import { RouteRecordRaw } from "vue-router";

const settingRouter: Array<RouteRecordRaw> = [
  {
    path: "general-settings",
    name: "hr-general-settings",

    meta: {
      permission: "hr_setting",
      title: "HR and Payroll Settings",
    },
    component: () =>
      import("@/views/modules/hr/views/settings/GeneralSettings/index.vue"),
  },
  {
    path: "trainings-settings",
    name: "hr-trainings-settings",
    meta: {
      permission: "hr_trainings",
      title: "trainingsSettings",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/trainings/List.vue"
      ),
  },
  {
    path: "trainers-settings",
    name: "hr-trainers-settings",
    meta: {
      permission: "hr_trainers",
      title: "trainersSettings",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/trainers/List.vue"
      ),
  },
  {
    path: "hr-dashboard",
    name: "hr-dashboard",
    meta: {
      permission: "hr_dashboard",
      title: "HR",
    },
    component: () =>
      import("@/views/modules/hr/views/dashboard/HrDashboard.vue"),
  },
  {
    path: "work-systems",
    name: "work-systems",
    meta: {
      permission: "hr_work_systems",
      title: "Work systems",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/work-systems/List.vue"
      ),
  },
  {
    path: "sanctions-reasons",
    name: "sanctions-reasons",
    meta: {
      permission: "hr_sanction_reasons",
      title: "Sanctions Reasons",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/sanctions-reasons/List.vue"
      ),
  },
  {
    path: "job-types",
    name: "job-types",
    meta: {
      permission: "hr_job_types",
      title: "Job Types",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/job-types/List.vue"
      ),
  },
  {
    path: "dismisses",
    name: "dismisses",
    meta: {
      permission: "hr_leave_requests",
      title: "Salary Cashing entities",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/dismisses/List.vue"
      ),
  },
  {
    path: "jobs",
    name: "jobs",
    meta: {
      permission: "hr_jobs",
      title: "Jobs",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/jobs/List.vue"
      ),
  },
  {
    path: "jobs-classifications",
    name: "jobs-classifications",
    meta: {
      permission: "hr_job_classifications",
      title: "Jobs Classifications",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/jobs-classifications/List.vue"
      ),
  },
  {
    path: "work-locations",
    name: "work-locations",
    meta: {
      permission: "hr_work_locations",
      title: "Work Locations",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/work-locations/List.vue"
      ),
  },
  {
    path: "evaluation-items",
    name: "evaluation-items",
    meta: {
      permission: "hr_evaluation_items",
      title: "Evaluation Items",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/evaluation-items/List.vue"
      ),
  },
  {
    path: "departments",
    name: "departments",
    meta: {
      permission: "hr_departments",
      title: "departments",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/departments/List.vue"
      ),
  },
  {
    path: "custodies",
    name: "custodies",
    meta: {
      permission: "hr_custodies",
      title: "Custodies",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/custodies/List.vue"
      ),
  },
  {
    path: "sanctions",
    name: "sanctions",
    meta: {
      permission: "hr_sanctions",
      title: "Sanctions",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/sanctions/List.vue"
      ),
  },
  {
    path: "employers",
    name: "employers",
    meta: {
      permission: "hr_employers",
      title: "Employers",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/employers/List.vue"
      ),
  },
  {
    path: "countries",
    name: "hr-countries",
    meta: {
      permission: "hr_countries",
      title: "Countries",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/countries/List.vue"
      ),
  },
  {
    path: "languages",
    name: "hr-languages",
    meta: {
      permission: "hr_languages",
      title: "Languages",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/languages/List.vue"
      ),
  },
  {
    path: "nationalities",
    name: "hr-nationalities",
    meta: {
      permission: "nationalities",
      title: "Nationalities",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/nationality/List.vue"
      ),
  },
  {
    path: "religions",
    name: "hr-religions",
    meta: {
      permission: "hr_religions",
      title: "Religions",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/religion/List.vue"
      ),
  },
  {
    path: "educational-qualifications",
    name: "hr-educational-qualifications",
    meta: {
      permission: "hr_educational_qualifications",
      title: "Educational Qualifications",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/educational-qualifications/List.vue"
      ),
  },
  {
    path: "social-status",
    name: "hr-social-status",
    meta: {
      permission: "hr_marital_statuses",
      title: "Marital status",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/social-status/List.vue"
      ),
  },
  {
    path: "educational-bodies",
    name: "hr-educational-bodies",
    meta: {
      permission: "hr_educational_institutions",
      title: "Educational Bodies",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/educational-bodies/List.vue"
      ),
  },
  {
    path: "specializations",
    name: "hr-specializations",
    meta: {
      permission: "hr_specialties",
      title: "Specializations",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/specializations/List.vue"
      ),
  },
  {
    path: "documents",
    name: "hr-documents",
    meta: {
      permission: "hr_documents",
      title: "Documents",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/document/List.vue"
      ),
  },
  {
    path: "appreciations",
    name: "hr-appreciations",
    meta: {
      permission: "hr_appreciations",
      title: "Appreciations",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/appreciations/List.vue"
      ),
  },
];

export default settingRouter;
