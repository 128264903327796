import AuthService from '@/core/services/AuthService';

const HrMenuConfig = () => {
   const visible = false;

   return [
      {
         heading: 'settings',
         route: '/settings',
         module_id: 4,
         pages: [
            {
               heading: 'HR and Payroll Settings',
               route: '/hr-management/general-settings',
               permission: 'hr_setting',
            },
            {
               sectionTitle: '_General Settings',
               sub: [
                  {
                     heading: 'Countries',
                     route: '/hr-management/countries',
                     permission: 'hr_countries',
                  },
                  {
                     heading: 'Nationalities',
                     route: '/hr-management/nationalities',
                     permission: 'nationalities',
                  },
                  {
                     heading: 'Languages',
                     route: '/hr-management/languages',
                     permission: 'hr_languages',
                  },
                  {
                     heading: 'Religions',
                     route: '/hr-management/religions',
                     permission: 'hr_religions',
                  },
                  {
                     heading: 'Marital status',
                     route: '/hr-management/social-status',
                     permission: 'hr_marital_statuses',
                  },
                  {
                     heading: 'Educational Qualifications',
                     route: '/hr-management/educational-qualifications',
                     permission: 'hr_educational_qualifications',
                  },

                  {
                     heading: 'Educational Bodies',
                     route: '/hr-management/educational-bodies',
                     permission: 'hr_educational_institutions',
                  },
                  {
                     heading: 'Specializations',
                     route: '/hr-management/specializations',
                     permission: 'hr_specialties',
                  },
                  {
                     heading: 'trainingsSettings',
                     route: '/hr-management/trainings-settings',
                     permission: 'hr_trainings',
                  },
                  {
                     heading: 'trainersSettings',
                     route: '/hr-management/trainers-settings',
                     permission: 'hr_trainers',
                  },
                  {
                     heading: 'Appreciations',
                     route: '/hr-management/appreciations',
                     permission: 'hr_appreciations',
                  },
               ],
            },

            {
               sectionTitle: 'Jobs Settings',
               sub: [
                  {
                     heading: 'Work systems',
                     route: '/hr-management/work-systems',
                     permission: 'hr_work_systems',
                  },
                  {
                     heading: 'Job Types',
                     route: '/hr-management/job-types',
                     permission: 'hr_job_types',
                  },
                  {
                     heading: 'Jobs',
                     route: '/hr-management/jobs',
                     permission: 'hr_jobs',
                  },
                  {
                     heading: 'Jobs Classifications',
                     route: '/hr-management/jobs-classifications',
                     permission: 'hr_job_classifications',
                  },
                  {
                     heading: 'Salary Cashing entities',
                     route: '/hr-management/dismisses',
                     permission: 'hr_leave_requests',
                  },
                  {
                     heading: 'departments',
                     route: '/hr-management/departments',
                     permission: 'hr_departments',
                  },
                  {
                     heading: 'Work Locations',
                     route: '/hr-management/work-locations',
                     permission: 'hr_work_locations',
                  },
               ],
            },

            {
               sectionTitle: 'Other Settings',
               sub: [
                  {
                     heading: 'Documents',
                     route: '/hr-management/documents',
                     permission: 'hr_documents',
                  },
                  {
                     heading: 'Custodies',
                     route: '/hr-management/custodies',
                     permission: 'hr_custodies',
                  },
                  {
                     heading: 'Sanctions',
                     route: '/hr-management/sanctions',
                     permission: 'hr_sanctions',
                  },
                  {
                     heading: 'Sanctions Reasons',
                     route: '/hr-management/sanctions-reasons',
                     permission: 'hr_sanction_reasons',
                  },
                  {
                     heading: 'Employers',
                     route: '/hr-management/employers',
                     permission: 'hr_employers',
                  },
                  {
                     heading: 'Evaluation Items',
                     route: '/hr-management/evaluation-items',
                     permission: 'hr_evaluation_items',
                  },
               ],
            },
         ],
      },
      {
         heading: 'Daily Transactions',
         route: '#',
         module_id: 4,
         id: 'dailyMovements',
         pages: [
            {
               heading: 'Employment Requests',
               route: '/hr-management/daily-transactions/employment-requests',
               permission: 'hr_employment_requests',
            },
            visible && {
               heading: 'recruitment',
               route: '/hr-management/daily-transactions/recruitments',
               permission: 'hr_recruitment_requests',
            },
            {
               heading: 'employees',
               route: '/hr-management/employees',
               meta: {
                  permission: 'employees',
                  title: 'Employment Requests',
               },
            },
            {
               heading: 'Transfer Transactions',
               route: '/hr-management/daily-transactions/transfer-transactions',
               permission: 'hr_transfer_movements',
            },
            {
               heading: 'vacation',
               route: '/hr-management/daily-transactions/vacation',
               permission: 'hr_vacation_movements',
            },
            {
               heading: 'Penalties',
               route: '/hr-management/daily-transactions/penalties',
               permission: 'hr_sanction_movements',
            },
            {
               heading: 'Contract Update',
               route: '/hr-management/daily-transactions/contract-update',
               permission: 'hr_update_contract_movements',
            },
            {
               heading: 'Document Update Transactions',
               route: '/hr-management/daily-transactions/document-update',
               permission: 'hr_document_movements',
            },
            {
               heading: 'Employees Evaluations',
               route: '/hr-management/daily-transactions/employees-evaluations',
               permission: 'hr_employee_rate_movements',
            },
            {
               heading: 'trainings',
               route: '/hr-management/daily-transactions/trainings-transactions',
               permission: 'hr_employment_requests',
            },
            {
               heading: 'Update Employee Id',
               route: '/hr-management/daily-transactions/code-transactions',
               permission: 'hr_employee_number_movements',
            },
            {
               heading: 'endOfService',
               route: '/hr-management/daily-transactions/termination',
               permission: 'hr_end_of_services',
            },
            {
               heading: 'leaveSettlement',
               route: '/hr-management/daily-transactions/leave-settlement',
               permission: 'hr_leave_settlements',
            },
         ].filter(Boolean),
      },
      {
         heading: 'Reports',
         route: '/reports',
         id: 'hrReports',
         pages: [
            {
               heading: 'employeesInfo',
               route: '/hr-management/employees-info',
               permission: 'hr_reports',
            },
            {
               heading: 'Personal Employee Info',
               route: '/hr-management/employees-personal-info',
               permission: 'hr_reports',
            },
            {
               heading: 'employeesCareerInfo',
               route: '/hr-management/employees-career-info',
               permission: 'hr_reports',
            },
            {
               heading: 'Employee Detailed Info',
               route: '/hr-management/employee-details',
               permission: 'hr_reports',
            },
            {
               heading: 'Transfer Transactions',
               route: '/hr-management/employees-transactions-info',
               permission: 'hr_reports',
            },
            {
               heading: 'vacation',
               route: '/hr-management/employees-leaves-info',
               permission: 'hr_reports',
            },
            {
               heading: 'leaveSettlement',
               route: '/hr-management/employees-leave-settlment-info',
               permission: 'hr_reports',
            },
            {
               heading: 'penalties',
               route: '/hr-management/employees-penalty-info',
               permission: 'hr_reports',
            },
            {
               heading: 'Custodies',
               route: '/hr-management/employees-custody-info',
               permission: 'hr_reports',
            },
            {
               heading: 'Documents',
               route: '/hr-management/employees-documents-info',
               permission: 'hr_reports',
            },
            {
               heading: 'documentsNeedRenew',
               route: '/hr-management/documents-need-renew',
               permission: 'hr_documents',
            },
            {
               heading: 'employeesEvaluation',
               route: '/hr-management/employees-evaluation-info',
               permission: 'hr_reports',
            },
            {
               heading: 'employeesTermination',
               route: '/hr-management/employees-termination-info',
               permission: 'hr_reports',
            },
         ],
      },
   ];
};
export default HrMenuConfig;
